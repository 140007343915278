export const PRIMARY_BLUE = '#00aaff';
export const PRIMARY_DARK = '#000';
export const PRIMARY_RED = '#c00707';
export const PRIMARY_WHITE = '#fff';
export const PRIMARY_GRAY = '#ebebeb';
export const SECONDARY_GREY = '#c4c4c4';
export const PRIMARY_DARK_GREY = 'rgba(0, 0, 0, 0.5)';
export const SECONDARY_DARK_GREY = 'rgba(0, 0, 0, 0.25)';
export const PRIMARY_ORANGE = '#FE5500';
export const PRIMARY_GREEN = 'rgb(77, 189, 116)';
export const PRIMARY_CARD_BLUE = '#66B8F9';

export const ADMIN_SIDEBAR_WIDTH = 240;
export const ADMIN_SIDEBAR_WIDTH_MINI = 76;
export const ADMIN_HEADER_HEIGHT = 58;
export const ADMIN_SIDEBAR_BG = '#D0D3DC';
export const ADMIN_SIDEBAR_TOGGLE_BG = '#f2f2f2';
export const ADMIN_MAP_SIDEBAR_WIDTH = 290;

export const SECONDARY_CARD_BLUE = '#3582BE';
export const PRIMARY_CARD_PURPLE = '#6846D3';
export const SIDEBAR_WIDTH = '233';

export const INVOICE_TOP = '#EAECF3';
export const INVOICE_BORDER = '#BDBDBD';
export const INVOICE_TOTAL_CONTAINER = '#D0D3DC';
export const INVOICE_HEADING = '#4F4F4F';
export const INVOICE_TABLE_HEADING = '#828282';

export const TABLE_HOVER = 'rgba(0,170,255,0.34)';
export const TABLE_ACTION_BUTTON = '#00AAFF';
export const TABLE_ACTION_BUTTON_HOVER = '#45bfff';

export const modalTypes = {
  'ADD_VENDOR_MODAL': 'add-vendor-modal',
  'CREATE_JOB_MODAL': 'create-job',
  'CREATE_TICKET_MODAL': 'create-ticket-modal',
  'EDIT_JOB_MODAL': 'edit-job-modal',
  'CANCEL_JOB_MODAL': 'cancel-job-modal',
  'EDIT_TICKET_MODAL': 'edit-ticket-modal',
  'CANCEL_SERVICE_TICKET_MODAL': 'cancel-service-ticket-modal',
  'VIEW_SERVICE_TICKET_MODAL': 'view-service-ticket-modal',
  'TERMS_AND_CONDITION_MODAL': 'terms-and-condition',
  'ADD_BRAND': 'add-brand',
  'ADD_JOB_TYPE': 'add-job-type',
  'ADD_EQIPMENT_TYPE': 'add-equipment-type',
  'ADD_JOB_SITE': 'add-job-site',
  'EDIT_CUSTOMER_INFO': 'edit-customer-info',
  'ADD_JOB_LOCATION': 'add-job-location',
  'SHOW_MAP_FILTER_POPUP': 'show-map-filter-popup',
  'JOB_REPORTS_MODAL': 'job-reports-modal',
  'ADD_BILLING_MODAL': 'add-billing-modal',
  'QUICKBOOKS_LOGIN_MODAL': 'quickbook-login-modal',
  'QUICKBOOKS_DISCONNECT_MODAL': 'quickbook-disconnect-modal',
  'ADD_CONTACT_MODAL': 'add-contact-modal',
  'DELETE_CONTACT_MODAL': 'delete-contact-modal',
  'EDIT_PROFILE': 'edit-profile-modal',
  'DELETE_BILLING_MODAL': 'delete-billing-modal',
  'MAKE_ADMIN_EMPLOYEE_MODAL': 'make-admin-employee-modal',
  'EMAIL_JOB_REPORT_MODAL': 'email-job-report-modal',
  'EMAIL_JOB_REPORT_MODAL_OLD': 'email-job-report-modal-old',
  'CONTRACT_VIEW_MODAL': 'contract-view-modal',
  'SHARED_FORM_MODAL': 'shared-form-modal',
  'EDIT_ITEM_MODAL': 'edit-item-modal',
  'SALES_TAX_MODAL': 'sales-tax-modal',
  'PAYMENT_TERMS_MODAL': 'payment-terms-modal',
  'UPDATE_PAYMENT_TERMS_MODAL': 'update-payment-terms-modal',
  'PAYMENT_RECORD_MODAL': 'payment_record_modal',
  'PAYMENT_HISTORY_MODAL': 'payment_history_modal',
  'EDIT_TIERS_MODAL': 'edit-tiers-modal'
};
