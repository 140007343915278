export const types = {
  'SET_REFRESH_SERVICE_TICKET_STATUS': 'SET_REFRESH_SERVICE_TICKET_STATUS',
  'SET_SERVICE_TICKET': 'SET_SERVICE_TICKET',
  'SET_SERVICE_TICKET_LOADING': 'SET_SERVICE_TICKET_LOADING',
  'SET_OPEN_SERVICE_TICKET_LOADING': 'SET_OPEN_SERVICE_TICKET_LOADING',
  'SET_OPEN_SERVICE_TICKET': 'SET_OPEN_SERVICE_TICKET',
  'SET_OPEN_SERVICE_TICKET_OBJECT': 'SET_OPEN_SERVICE_TICKET_OBJECT',
  'SET_CLEAR_OPEN_SERVICE_TICKET_OBJECT': 'SET_CLEAR_OPEN_SERVICE_TICKET_OBJECT',
  'SET_OPEN_TICKET_FILTER_STATE': 'SET_OPEN_TICKET_FILTER_STATE',
  'SET_CLEAR_TICKET_FILTER_STATE': 'SET_CLEAR_OPEN_TICKET_FILTER_STATE',
  'SET_SERVICE_TICKET_NOTIFICATION': 'SET_SERVICE_TICKET_NOTIFICATION',
  'SET_SELECTED_CUSTOMERS': 'SET_SELECTED_CUSTOMERS',
  'GET_SERVICE_TICKET_DETAIL': 'GET_SERVICE_TICKET_DETAIL'
};
